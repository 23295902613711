.wrapper {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  padding-top: 80px;
  position: relative;
  @media screen and (max-width: 750px) {
    padding-top: 0;
  }

}

.title {
  position: absolute;
  text-align: center;
  text-shadow: 1px 0 0 #2F2F2F, -1px 0 0 #2F2F2F, 0 1px 0 #2F2F2F, 0 -1px 0 #2F2F2F;
  color: #ECECEC;
  font-size: 220px;
  font-family: Montserrat;
  font-style: italic;
  font-weight: bold;
  margin: auto;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  line-height: 1;

  @media screen and (max-width: 1350px) {
    font-size: 150px;
    top: 100px;
  }

  @media screen and (max-width: 950px) {
    font-size: 100px;
  }

  @media screen and (max-width: 750px) {
    font-size: 40px;
    position: static;
    text-align: left;
    color: white;
  }

  @media screen and (max-width: 750px) {
    font-size: 40px;
    position: static;
    text-align: left;
    color: white;
  }
}