.wrapper {
    grid-column: auto / span 2;
    color: #000;
    .table {
        width: 100%;
        position: relative;
        max-width: 570px;
        border-collapse: collapse;
        @media screen and (max-width: 720px) {
            max-width: unset;
        }
    }
    .table_open {
        width: 100%;
        height: 100%;
        position: relative;
        max-width: 570px;
        border-collapse: collapse;
        @media screen and (max-width: 720px) {
            max-width: unset;
        }
    }
    .columns {
        display: flex;
        justify-content: space-between;
        margin-bottom: 13px;
        padding: 0 13px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        text-align: center;
        >div:first-child {
            width: 23%;
            @media screen and (max-width: 720px) {
                width: 100%;
            }
        }
        div {
            width: 100%;
        }
    }
    .header {
        background: #FFD600;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.55);
        border-radius: 10px 10px 0 0;
        overflow: hidden;
        position: relative;
        &_title {
            height: 100px;
            position: relative;
        }
    }
    .header_open {
        background: #FFD600;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.55);
        border-radius: 10px 10px 0 0;
        overflow: hidden;
        position: relative;
        height: 100%;
        &_title {
            height: 100px;
            position: relative;
        }
    }
    .body {
        z-index: 0;
        box-shadow: 12px 16px 18px rgba(0, 0, 0, 0.25);
        border-radius: 0 0 10px 10px;
        &_canvas {
            background-color: #E5E5E5;
            padding: 0 15px;
            >div:hover,
            >div:hover a {
                color: #FFD600;
            }
            >div {
                display: flex;
                justify-content: space-around;
                align-items: center;
                >div {
                    height: 44px;
                    line-height: 44px;
                    width: 100%;
                    text-align: center;
                }
                >div:first-child {
                    width: 23%;
                    @media screen and (max-width: 720px) {
                        width: 100%;
                    }
                }
            }
            >div:last-child {
                >div {
                    height: 100%;
                }
                div:first-child {
                    border-radius: 0 0 0 10px;
                }
                div:last-child {
                    border-radius: 0 0 10px 0;
                }
            }
            >div:nth-child(odd) {
                >div:nth-child(odd) {
                    background-color: #bcbcbc;
                }
                >div:nth-child(even) {
                    background-color: #a9a9a9;
                }
            }
            >div:nth-child(even) {
                >div:nth-child(odd) {
                    background-color: #ababab;
                }
                >div:nth-child(even) {
                    background-color: #9a9a9a;
                }
            }
        }
    }
    .footer {
        &_canvas {
            border-radius: 0 0 10px 10px;
            background-color: #E5E5E5;
            padding: 20px 15px 15px 15px;
            display: flex;
            flex-direction: column;
            align-items: center;
            button {
                width: 192px;
                height: 34px;
                background: #FFD600;
                border-radius: 10px;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: #2F2F2F;
            }
            >div:first-child {
                justify-content: space-between;
                background: #FFD600;
                border-radius: 10px;
                width: 100%;
                display: flex;
                flex-direction: row;
                padding: 14px 12px;
                margin-bottom: 14px;
                box-sizing: border-box;
                font-family: Montserrat;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                div:first-child {
                    width: 23%;
                    @media screen and (max-width: 720px) {
                        width: 100%;
                    }
                }
                div {
                    text-align: center;
                    width: 100%;
                }
            }
        }
        @media screen and (max-width: 720px) {
            margin-right: 0;
            padding: 0;
        }
    }
    .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        color: #2F2F2F;
        text-align: left;
        padding-top: 21px;
        padding-left: 49px;
        text-transform: uppercase;
        max-width: 250px;
        @media screen and (max-width: 1024px) {
            max-width: 200px;
        }
        @media screen and (max-width: 600px) {
            max-width: 75%;
        }
    }
    .number {
        position: absolute;
        top: 0;
        right: 15px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: bold;
        font-size: 60px;
        line-height: 73px;
        color: #FFD600;
        text-shadow: 1px 0 0 #2F2F2F, -1px 0 0 #2F2F2F, 0 1px 0 #2F2F2F, 0 -1px 0 #2F2F2F;
        @media screen and (max-width: 950px) {
            font-size: 50px;
            right: 5px;
        }
        @media screen and (max-width: 720px) {
            font-size: 30px;
            line-height: 35px;
        }
        sub {
            font-family: Montserrat;
            font-style: normal;
            font-weight: bold;
            font-size: 11px;
            line-height: 13px;
            color: #2F2F2F;
            position: absolute;
            right: 0;
            bottom: 0;
            height: 13px;
            text-align: center;
            text-shadow: none;
            white-space: nowrap;
            @media screen and (max-width: 1024px) {
                left: auto;
                bottom: -5px;
            }
        }
    }
    .help {
        position: absolute;
        width: 28px;
        height: 28px;
        border: 2px solid #2F2F2F;
        border-radius: 10px;
        cursor: pointer;
        left: 7px;
        top: 7px;
        background-color: transparent;
        img {
            margin-top: 5px;
        }
    }
    @media screen and (max-width: 720px) {
        grid-column: auto / span 4;
    }
}

.tipText {
    padding: 0 13px 13px 13px;
}