.wrapper{
  grid-column: auto / span 4;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;

  .body{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0px 30px;
    grid-template-rows: repeat(4, 1fr);

    .number{
      position: absolute;
      left: 5px;

      font-family: Montserrat;
      font-style: italic;
      font-weight: bold;
      font-size: 30px;
      line-height: 44px;

      color: #FFD600;
      text-shadow: 1px 0 0 #2f2f2f, -1px 0 0 #2f2f2f, 0 1px 0 #2f2f2f, 0 -1px 0 #2f2f2f;
    }


    > div:first-child{
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      text-align: center;

      color: #2F2F2F;
      align-items: baseline;

      > div:first-child{
        padding-left: 50px;
      }
    }

    > div:not(:first-child)
    {
      background: #cfcfcf;
      margin-bottom: 10px;
      font-family: Montserrat;
      font-style: italic;
      font-weight: bold;
      font-size: 16px;
      text-align: center;

      color: #000000;

      > div:first-child{
        background: #FFD600;
        box-shadow: 4px 0 4px rgba(0, 0, 0, 0.25);
        border-radius: 0 10px 10px 0;
        position: relative;
        padding: 0 5px 0 50px;
      }
    }

    > div:nth-child(2){
      background: initial;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      text-align: center;

      color: #2F2F2F;
      align-items: baseline;

      > div:first-child{
        padding: initial;
        background: initial;
        padding-left: 50px;
      }
      @media screen and (max-width: 720px){
        display: none;
      }
    }

    > div{
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      line-height: 44px;
      text-align: center;

      > div{
        height: 100%;
        width: 100%;
      }

      @media screen and (max-width: 720px) {
        > div:nth-child(2){
          display: none;
        }
        > div:nth-child(3){
          display: none;
        }
        > div:nth-child(5){
          display: none;
        }
      }
    }

    > *:first-child, > *:last-child {
      width: 100%;
    }

    @media screen and (max-width: 720px){
      grid-template-columns: 1fr;
    }
  }

  h2{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
    color: #2F2F2F;
  }

  .middle{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 10px;


    > *:first-child{
      margin-top: 10px;
    }

    > *{
      height: 44px;
      margin-bottom: 10px;

      div {
        background: -webkit-linear-gradient(rgba(47,47,47, 1), rgba(47,47,47, 0));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .versus{
    font-family: Montserrat;
    font-style: italic;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
  }

  @media screen and (max-width: 1350px){
    grid-column: auto / span 4;
  }
}