.wrapper {
    position: relative;
}

.faq {
    display: grid;
    grid-gap: 20px;
    padding-top: 120px;
    position: relative;
    z-index: 1;
    @media screen and (max-width: 950px) {
        padding-top: 60px;
    }
}

.title {
    position: absolute;
    text-align: center;
    text-shadow: 1px 0 0 #2f2f2f, -1px 0 0 #2f2f2f, 0 1px 0 #2f2f2f, 0 -1px 0 #2f2f2f;
    font-size: 220px;
    font-family: Montserrat;
    font-style: italic;
    font-weight: bold;
    color: #ececec;
    margin: auto;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    text-transform: uppercase;
    @media screen and (max-width: 950px) {
        font-size: 100px;
    }
    @media screen and (max-width: 720px) {
        color: white;
    }
    @media screen and (max-width: 400px) {
        font-size: 75px;
        top: 140px;
    }
}

.question {
    position: absolute;
}

.footerImg {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 0;
    @media screen and (max-width: 1024px) {
        display: none;
    }
}