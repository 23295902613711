.sidebar {
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 90px;
    height: 100%;
    background: #D4D4D4;
    padding-top: 100px;

    @media screen and ( max-width: 720px ) {
        & {
            display: none;
        }        
    }
}

.navigation {
    display: grid;
    justify-items: center;
    gap: 6px;

    @media screen and ( max-width: 720px) {
        & {
            justify-items: self-start;
            padding-left: 11px;     
        }

        width: 100%;
        padding: 0 20px !important;
    }
}

.item {
    width: 66px;
    height: 80px;
    border: 2px solid #A8A8A8;
    border-radius: 5px;
    padding-top: 14px;
    opacity: 0.3;

    @media screen and (max-width: 720px) {
        width: 100%;
    }
}
.activeItem{
    opacity: 1;
}

.image {
    display: flex;
    justify-content: center;
}

.title {
    margin-top: 8px;
    text-align: center;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    color: #2F2F2F;
}

.wrapped {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #0000008f;
    padding-top: 100px;
    z-index: 2;

    @media screen and ( max-width: 720px ) {
        background: #D4D4D4;
    }

    @media screen and ( min-width: 720px ) {
        & {
            display: none;    
        }
    }    
}

.popup {
    position: relative;
    width: 320px;
    background: #D4D4D4;
    padding: 50px 0;
    display: flex;

    @media screen and (max-width: 720px)
    {
        width: 100%;
        height: 100%;

    }
}

.other {
    margin-left: 15px;
    padding-top: 160px;

    @media screen and (max-width: 720px){
        display: none;
    }
}

.svg {
    margin-right: 10px;    
}

.link {
    margin-bottom: 20px;
    display: flex;    
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;

    @media screen and (max-width: 720px) {
        display: none;
    }
}