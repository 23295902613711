.header {
    z-index: 10;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    padding-top: 30px;

    @media screen and ( max-width: 720px ) {
        & {
            margin: 0 15px; 
        }
    } 
}

.navigation {
    display: flex; 

    @media screen and ( max-width: 720px ) {
        & {
            display: none;    
        }
    }   
}

.item {
    margin-left: 50px;
    display: flex;
    align-items: center;
}

.title {
    margin-left: 20px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: #2F2F2F;   
}

.burger {
    display: none;
    z-index: 999;
    position: fixed;

    @media screen and ( max-width: 720px ) {
        & {
            display: block;    
        }
    }   
}