@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,600;1,900&display=swap');

.wrapper {
  position: relative;
  color: #2f2f2f;
  padding-bottom: 50px;

  .body {
    padding-top: 5px;
    position: relative;

  }

  .titleWrapper {
    text-align: center;
    text-shadow: 1px 0 0 #2F2F2F, -1px 0 0 #2F2F2F, 0 1px 0 #2F2F2F, 0 -1px 0 #2F2F2F;
    color: #ECECEC;
    font-size: 150px;
    font-family: Montserrat;
    font-style: italic;
    font-weight: bold;
    text-transform: uppercase;

    @media screen and (max-width: 1350px) {
      font-size: 120px;
      top: 100px;
    }

    @media screen and (max-width: 950px) {
      font-size: 100px;
    }

    @media screen and (max-width: 950px) {
      font-size: 100px;
    }

    @media screen and (max-width: 768px) {
      font-size: 65px;
    }

    @media screen and (max-width: 576px) {
      font-size: 30px;
      text-align: left;
      color: white;
    }
  }

  .title {
    position: relative;
    display: inline-flex;
    line-height: 1.2;
  }

  .img {
    position: absolute;
    right: -290px;
    top: -40px;
    max-width: 455px;
    @media (max-width: 1440px) {
      display: none;
    }
  }
}

.teamAbout {
  display: grid;
  grid-template-columns: 170px 1fr;
  grid-column-gap: 30px;
  margin-bottom: 30px;


  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
    grid-row-gap: 15px;
  }

  .team {
    height: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(180deg, #FFD600 0%, #FFF383 100%);
    border-radius: 10px;
    padding: 0 30px;


    @media screen and (max-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      height: 170px;
    }
  }

  .teamImg {
    max-width: 100px;
    margin-top: 30px;
    margin-bottom: 65px;
    @media screen and (max-width: 768px) {
      margin: 0;
    }
  }

  .teamName {
    font-family: 'Montserrat', sans-serif;
    font-style: italic;
    font-weight: 900;
    font-size: 20px;
    line-height: 24px;
  }

  .teamDescr {
    padding: 12px 15px;
    background: #E5E5E5;
    border-radius: 10px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }
}

.teammates {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(7, 1fr);
  margin-bottom: 30px;

  @media screen and (max-width: 1440px) {
    grid-template-columns: repeat(6, 1fr);
  }

  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 425px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .teammate {
    padding: 8px 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background: linear-gradient(180deg, #FFD600 0%, #FFF383 100%);
    border-radius: 10px;
  }

  .teammateName{
    margin: 10px auto;
  }

  .teammateBuff{
    text-align: center;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
}

.tableContainer{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}