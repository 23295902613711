@import url('https://fonts.googleapis.com/css?family=Montserrat');
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body,
html {
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    background: #ECECEC;
    font-family: Montserrat;
    font-style: normal;
}

a {
    text-decoration: none;
    color: white;
}

button {
    border: none;
    outline: none;
    cursor: pointer;
    background: none;
    color: white;
}

.container {
    padding: 15px;
    max-width: 1158px;
    align-self: center;
    width: 100%;
}

.app {
    display: flex;
    flex-direction: column;
    color: white;
    /** Sidebar padding**/
    padding-left: 90px;
}

@media screen and (max-width: 720px){
    .app{
        padding-left: 0;
        background: linear-gradient(176.65deg, #FFFFFF 0.37%, rgba(255, 255, 255, 0) 111.66%);
    }
    .container{
        padding: 5px;
    }
}