.wrapper {
    position: relative;
}

.background {
    position: absolute;
    text-align: center;
    text-shadow: 1px 0 0 #2f2f2f, -1px 0 0 #2f2f2f, 0 1px 0 #2f2f2f, 0 -1px 0 #2f2f2f;
    font-size: 150px;
    font-family: Montserrat;
    font-style: italic;
    font-weight: bold;
    color: #ececec;
    margin: auto;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    text-transform: uppercase;
    @media screen and (max-width: 950px) {
        font-size: 100px;
    }
    @media screen and (max-width: 720px) {
        color: white;
    }
    @media screen and (max-width: 400px) {
        font-size: 75px;
        top: 140px;
    }
}

.content {
    position: relative;
    z-index: 1;
    padding-top: 120px;
    padding-bottom: 50px;
}

.block {
    width: 100%;
    height: 800px;
    background: #E5E5E5;
    border-radius: 10px;
    filter: drop-shadow(12px 16px 18px rgba(0, 0, 0, 0.25));
    padding-top: 180px;
    position: relative;
    @media screen and (max-width: 624px) {
        padding-top: 90px;
    }
}

.header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 128px;
    background: #FFD600;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.55);
    border-radius: 10px 10px 0px 0px;
    padding-top: 28px;
    padding-left: 20px;
    img {
        position: absolute;
        top: -70px;
        left: 45%;
        @media screen and (max-width: 624px) {
            top: -10px;
            width: 32px;
            height: 32px;
        }
    }
    @media screen and (max-width: 624px) {
        height: 58px;
        padding-left: 7px;
    }
}

.title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #2F2F2F;
    text-transform: uppercase;
    @media screen and (max-width: 624px) {
        font-size: 16px;
        line-height: 20px;
    }
}

.body {
    height: 100%;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 60px;
}

.navigation {
    padding-left: 40px;
    button {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #565656;
        padding-bottom: 5px;
        @media screen and (max-width: 624px) {
            background: #BCBCBC;
            border-radius: 10px;
            font-size: 12px;
            line-height: 15px;
            color: #E5E5E5;
            padding: 10px;
        }
    }
    @media screen and (max-width: 624px) {
        padding: 0;
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }
}

.active {
    color: #000000 !important;
    border-bottom: 3px solid #FFD600;
    @media screen and (max-width: 624px) {
        background: #FFD600 !important;
        border-radius: 10px;
    }
}

.margin {
    margin-left: 134px;
    @media screen and (max-width: 624px) {
        margin: 0;
    }
}

.textarea {
    margin-top: 12px;
    width: 100%;
    height: 100%;
    background: #D5D5D5;
    border-radius: 10px;
    padding: 20px;
    color: black;
    overflow-y: auto;
    text-align: center;
    direction: rtl;
    p {
        line-height: 40px;
    }
    &::-webkit-scrollbar {
        width: 4px;
        background: #646464;
    }
    &::-webkit-scrollbar-thumb {
        background: black;
    }
}