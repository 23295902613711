.card {
    background: #c3c2c2;
    border-radius: 10px;
}

.header {
    background: #FFD600;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.55);
    border-radius: 10px 10px 0px 0px;
    display: flex;
    justify-content: space-between; 
    padding: 21px 19px 17px 63px;
    cursor: pointer;

    @media screen and ( max-width: 639px ) {
        & {
            padding: 16px 16px 56px 16px;
        }
    }
}

.question {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    color: #2F2F2F; 

    @media screen and ( max-width: 639px ) {
        & {
            font-size: 16px;
            line-height: 20px; 
        }
    }
}

.arrow{
    transform: rotate(0deg);
    transition: .2s ease; 
    &.open{
        transform: rotate(180deg);
    } 
}

@media screen and ( max-width: 639px ) {
    .arrow {
        display: none;    
    }
    .arrow_open {
        display: none;    
    }
}

.body {
    padding: 15px 44px;

    @media screen and ( max-width: 639px ) {
        & {
            padding: 20px;    
        }
    }   
}

.answer {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #000000;    
}