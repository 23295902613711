.popup {
  backdrop-filter: blur(14px);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 55px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 998;
  overflow-y: auto;
  color: #000000;

  &__table {
    width: 100%;
    position: relative;
    max-width: 570px;
    border-collapse: collapse;

    @media screen and (max-width: 720px) {
      max-width: unset;
    }
  }

  &__columns {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
    padding: 0 13px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    text-align: center;

    > div:first-child {
      width: 23%  ;
      @media screen and (max-width: 720px){
        width: 100%;
      }
    }

    div {
      width: 100%;
    }
  }

  &__header {
    background: #FFD600;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.55);
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    margin-left: 32px;
    position: relative;

    &_title {
      height: 100px;
      position: relative;
    }

    @media screen and (max-width: 720px){
      margin: 0 5px;
    }
  }

  &__body::-webkit-scrollbar {
    width: 4px;

  }

  &__body::-webkit-scrollbar-thumb {
    background-color: #000;
  }

  &__body {

    padding: 0 28px;
    max-height: 570px;
    overflow-y: scroll;
    direction: rtl;
    margin-right: -28px;
    z-index: 0;

    &_canvas {
      background-color: #E5E5E5;
      padding: 0 15px;

      > div {
        direction: ltr;
        display: flex;
        justify-content: space-around;
        align-items: center;

        > div {
          height: 44px;
          line-height: 44px;
          width: 100%;
          text-align: center;
        }


        > div:first-child {
          width: 23%;
          @media screen and (max-width: 720px){
            width: 100%;
          }
        }
      }

      > div:last-child {
        height: 44px;

        > div {
          height: 100%;
        }

        div:first-child {
          border-radius: 0 0 0 10px;
        }

        div:last-child {
          border-radius: 0 0 10px 0;
        }
      }

      > div:nth-child(odd) {
        > div:nth-child(odd) {
          background-color: #bcbcbc;
        }

        > div:nth-child(even) {
          background-color: #a9a9a9;
        }
      }

      > div:nth-child(even) {
        > div:nth-child(odd) {
          background-color: #ababab;
        }

        > div:nth-child(even) {
          background-color: #9a9a9a;
        }
      }
    }

    @media screen and (max-width: 720px){
      margin-left: -4px;
      margin-right: 0;
      padding: 0;
    }
  }

  &__footer {
    padding: 0 32px;
    margin-right: -32px;
    border-radius: 0 0 10px 10px;

    &_canvas {
      background-color: #E5E5E5;
      padding: 20px 15px 15px 15px;
      display: flex;
      flex-direction: column;
      align-items: center;

      > div:first-child {
        justify-content: space-between;
        background: #FFD600;
        border-radius: 10px;
        width: 100%;
        display: flex;
        flex-direction: row;
        padding: 14px 12px;
        box-sizing: border-box;

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #000;
        margin-bottom: 20px;

        div:first-child {
          width: 23%;

          @media screen and (max-width: 720px){
            width: 100%;
          }
        }

        div {
          text-align: center;
          width: 100%;
        }
      }

      button {
        background: #FFD600;
        border-radius: 10px;
        border: 0;
        align-self: flex-start;
        padding: 8px 24px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;

        color: #2F2F2F;
      }
    }

    @media screen and (max-width: 720px){
      margin-right: 0;
      padding: 0;
    }
  }

  &__title {
    max-width: 75%;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;

    color: #2F2F2F;
    text-align: left;
    padding-top: 21px;
    padding-left: 49px;
    text-transform: uppercase;
  }

  &__number {
    position: absolute;
    top: 0;
    right: 49px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 73px;
    color: #FFD600;

    text-shadow: 1px 0 0 #2F2F2F, -1px 0 0 #2F2F2F, 0 1px 0 #2F2F2F, 0 -1px 0 #2F2F2F;

    @media screen and (max-width: 720px) {
      right: 5px;
    }

    @media screen and (max-width: 380px) {
      font-size: 30px;
      line-height: 35px;
    }

  }

  &__help {
    position: absolute;
    width: 28px;
    height: 28px;
    border: 2px solid #2F2F2F;
    border-radius: 10px;
    cursor: pointer;
    left: 7px;
    top: 7px;
    background-color: transparent;

    img {
      margin-top: 5px;
    }
  }

  &__close {
    width: 28px;
    height: 28px;
    position: absolute;
    top: -50px;
    right: -44px;
    border: 2px solid #2F2F2F;
    border-radius: 10px;
    cursor: pointer;
    background-color: transparent;

    @media screen and (max-width: 720px) {
      right: 0;
      width: 35px;
      height: 35px;
    }
  }

  @media screen and (max-width: 720px) {
    padding: 55px 10px 0 10px;
  }
}