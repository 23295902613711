.wrapper {
    position: relative;
    .body {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 30px;
        padding-top: 80px;
        position: relative;
        >* {
            width: 100%;
        }
    }
    .title {
        position: absolute;
        text-align: center;
        text-shadow: 1px 0 0 #2F2F2F, -1px 0 0 #2F2F2F, 0 1px 0 #2F2F2F, 0 -1px 0 #2F2F2F;
        color: #E5E5E5;
        font-size: 220px;
        font-family: Montserrat;
        font-style: italic;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin: auto;
        left: 0;
        right: 0;
        text-transform: uppercase;
        @media screen and (max-width: 1350px) {
            font-size: 150px;
            top: 100px;
        }
        @media screen and (max-width: 950px) {
            font-size: 100px;
        }
        @media screen and (max-width: 400px) {
            font-size: 30px;
            color: #fff;
            left: auto;
            right: initial;
            top: 0;
        }
    }
}